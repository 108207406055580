<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <NewsDialog ref="openingNewsDialog"></NewsDialog>
    <v-row>
      <v-col align="center" class="mt-15 mb-15">
        <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
          <v-row>
            <!-- Chapter Title -->
            <v-col align="left" cols="12" class="mt-15 pb-0">
              <p class="caHeadline">News</p>
            </v-col>
            <v-col align="left" cols="12" v-if="allTheNews == ''">
              <p class="text-center title">No news made public yet.</p>
            </v-col>

            <!-- News Cards -->
            <v-col
              align="left"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              v-for="(newsData, newsDataIndex) in allTheNews"
              :key="newsDataIndex"
            >
              <v-card
                class="newsCard"
                height="100%"
                @click="getNewsContent(newsData)"
              >
                <v-row>
                  <v-col cols="12" class="pb-4">
                    <v-row>
                      <v-col cols="12">
                        <v-img
                          v-if="newsData.media_url"
                          :src="newsData.media_url"
                          alt="News Image"
                          height="200px"
                          cover
                          class="newsImage"
                        ></v-img>
                        <div
                          v-else
                          style="
                            height: 200px;
                            width: auto;
                            background-color: #d1d1d1;
                            margin: 0px 15px 0px 15px;
                          "
                        ></div>
                      </v-col>
                      <v-col cols="12" style="" class="pa-3">
                        <p class="pl-2 mb-0 mb-2 newsCardTitle">
                          {{ newsData.title }}
                        </p>
                        <p class="pa-2 pb-0 mb-2 newsCardDescription">
                          {{ newsData.description }}
                        </p>
                      </v-col>
                      <v-col cols="12" class="pt-0 mt-0 ml-2">
                        <button class="cardbutton mb-2">
                          <p class="cardbuttontext">Read more</p>
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mb-15">
        <v-row>

          <v-col cols="12">
            <p class="newsChapterTitle">News</p>
          </v-col>

          <v-col cols="12" class="mb-15">
            <p class="newsChapterDescription">Keep up to date about the Path</p>
          </v-col>

          <v-col cols="12" v-if="allTheNews == ''">
            <p class="text-center title">No news made public yet.</p>
          </v-col>


          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(newsData, newsDataIndex) in allTheNews" :key="newsDataIndex">
            <v-card height="100%" @click="getNewsContent(newsData)">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-row>
                    <v-col cols="12">
                      <v-img
                        v-if="newsData.media_url"
                        :src="newsData.media_url"
                        alt="News Image"
                        width="auto"
                        height="200px"
                        cover
                        class="ml-2 mr-2"
                      ></v-img>
                      <div v-else style="height: 200px; width: auto; background-color: #d1d1d1; margin: 0px 15px 0px 15px"></div>
                    </v-col>
                    <v-col cols="12" style="height: 110px" class="mb-0 ml-2 pb-0">
                      <p class="pl-2 mb-0 newsCardTitle">{{ newsData.title }}</p>
                      <p class="pa-2 mb-0 pb-0 newsCardDescription">{{ newsData.description }}</p>
                    </v-col>
                    <v-col cols="12" class="pt-0 mt-0">
                      <v-card-actions class="pt-0">
                        <p class="newsCardButton mr-2 pt-3">Read article</p>
                        <v-icon class="newsCardButtonArrow">mdi-arrow-right</v-icon>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                  <v-divider
                    class="newsCardDividerPositioning"
                    width="98%"
                    style="padding: 2px"
                    :style="`background-color:${colorArr[newsDataIndex]}`"
                  ></v-divider>
                </v-col>
                <v-col cols="12"></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/newsDialog.vue";
export default {
  name: "News",
  components: {
    NewsDialog,
  },
  data() {
    return {
      // Fix
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      // Can check 106/7
      tenant: 120,
      allTheNews: [],
      theNewsContent: [],
      colorArr: [
        "#205072",
        "#329D9C",
        "#D83636",
        "#DD9A30",
        "#205072",
        "#329D9C",
      ],
    };
  },
  mounted() {
    this.getAllNews();
  },
  methods: {
    // Get all news made for this tenant
    getAllNews() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/news?mode=getpublicnews&tenant_id=${this.tenant}`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.allTheNews = response.data.reverse();
          console.log("Responsen", response.data);
        });
    },

    // Get all the content of the clicked news
    getNewsContent(data) {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/news_content?mode=getpublicnews&news_id=${data.id}`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.theNewsContent = response.data;
          console.log("Responsen", response.data);
        })
        .then((responsen) => {
          this.openTheDialog(data, this.theNewsContent);
        });
    },

    // Open the Dialog while passing the correct data
    openTheDialog(originalData, contentData) {
      this.$refs.openingNewsDialog.openNewsDialog(originalData, contentData);
    },
  },
};
</script>

<style scoped>
/* General Page */
/* .homePageWidth {
  width: 90vw; 
  margin:auto auto;
} */
</style>
