<template>
  <v-footer color="#FFFFFF" class="footerStyling pt-12 pb-12">
    <v-row style="width: 100%" no-gutters>
      <v-col cols="12" align="center">
        <v-col cols="12" align="left" xl="8" lg="9" md="12" sm="12" xs="12" class="">
          <v-row>
            <!-- <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" align-self="center" class="pt-12">
              <p class="caBody">Footer design will change depending on content</p>
              <p class="caBody">Contact info</p>
            </v-col> -->
            <!-- <v-col align="right" cols="12" xl="6" lg="6" md="12" sm="12" xs="12" class="mb-0 pb-0">
              <div>
                <v-img class="" src="../../../public/img/eulogo.jpg" height="100px" width="100px" contain></v-img>
              </div>
            </v-col> -->
          </v-row>
        </v-col>
      </v-col>

      <!-- Erasmus Logo -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" v-if="$vuetify.breakpoint.mdAndDown">
        <v-img src="../../../public/img/eulogo.jpg" contain max-width="300"></v-img>
      </v-col>
      <v-col v-else cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <v-img src="../../../public/img/eulogo.jpg" contain></v-img>
      </v-col>
      <!-- Text -->
      <!-- <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-0 pt-3" v-if="$vuetify.breakpoint.mdAndDown">
        <p class="footerText">Footer design will change depending on content</p>
        <p class="footerText">Contact info</p>
      </v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="pl-15 pt-3" v-else>
        <p class="footerText">Footer design will change depending on content</p>
        <p class="footerText">Contact info</p>
      </v-col> -->
      <!-- Image -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <v-img class="mt-5" src="img/EVOLVE.png"  max-height="130" max-width="130" contain></v-img>
        <p class="pt-15" style="font-weight: bold; font-size: 20px; color: blue">M@thgan</p>
      </v-col> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.footerStyling {
  /* padding: 35px 0 50px 0; */
  background-color: #ffffff !important;
  box-shadow: 0px 2px 20px #00000029 !important;
}
.footerStyling::before {
  height: 6px;
  background-color: var(--brand-color-secondary);
  bottom: calc(100% - 18px);
  width: calc(100% - 24px);
  margin: auto auto auto -4px;
  content: "";
  position: absolute;
  border-radius: 100px;
}

.cursorStyle {
  cursor: pointer;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.dividerStyling {
  background-color: #329d9c;
  border-radius: 4px;
  opacity: 1;
  padding: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footerText {
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #6a6a6a;
  opacity: 1;
  font-size: 14px;
}
</style>
