<template>
  <div>
    <NewsDialog ref="openingNewsDialog"></NewsDialog>
    <RssDialog ref="openingRssDialog"></RssDialog>

    <!-- Snackbar for Contact Form -->
    <v-snackbar v-model="contactToast" dark color="success">
      <span>The message has been sent!</span>
      <v-btn class="float-right" x-small dark icon @click="contactToast = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Home Page Content -->
    <v-row style="width: calc(100% - 2px); margin: 0 11px 0 1px">
      <!-- <v-col cols="12" :style="$vuetify.breakpoint.mdAndDown ? 'margin-top: 24px' : 'margin-top: 62px'"></v-col> -->
      <v-col cols="12" v-if="$vuetify.breakpoint.xlOnly" style="margin-top: 62px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.lgOnly" style="margin-top: 62px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.mdOnly" style="margin-top: 24px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.smOnly" style="margin-top: 32px"></v-col>
      <v-col cols="12" v-else-if="$vuetify.breakpoint.xsOnly" style="margin-top: 32px"></v-col>
      <!-- Intro Image -->
      <v-col cols="12" class="pb-0">
        <v-card elevation="0" style="border-radius: 7px">
          <v-img
            src="https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/0bc27356-ae4b-4c62-82a9-596234146684.jpg"
            height="650"
          >
            <p v-if="$vuetify.breakpoint.mdAndDown" class="caHeadline introTitlePhone">Welcome to Path</p>
            <p
              v-else
              class="caHeadline"
              style="margin-left: 17%; margin-top: 10%; color: white; text-shadow: 1px 1px 4px #000000"
            >
              PATH
            </p>
            <p v-if="$vuetify.breakpoint.mdAndDown" class="introDescriptionPhone">
              PATH - Professional tool for effective career development
            </p>
            <p v-else class="caBody introDescription">PATH - Professional tool for effective career development</p>
          </v-img>
        </v-card>
      </v-col>

      <!-- 3 Cards in center of cards -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col> -->
      <v-col cols="12" align="center">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12" class="pt-0">
          <v-row>
            <v-col
              cols="12"
              align="left"
              xl="3"
              lg="3"
              md="6"
              sm="12"
              xs="12"
              v-for="(pageCard, pageCardIndex) in pageCards"
              :key="pageCardIndex"
              class="mb-0 pb-0"
            >
              <v-card class="pageCard pa-4" :to="pageCard.link" height="100%" style="position: relative">
                <!-- <v-divider v-if="pageCardIndex == 0" class="pageCardDividerBlue"></v-divider>
              <v-divider v-if="pageCardIndex == 1" class="pageCardDividerGreen"></v-divider>
              <v-divider v-if="pageCardIndex == 2" class="pageCardDividerRed"></v-divider> -->
                <v-row justify="space-between">
                  <v-col cols="8">
                    <p class="mt-2 pageCardTitle" style="white-space: nowrap">
                      {{ pageCard.name }}
                    </p>
                  </v-col>
                  <v-col cols="4" class="flex-shrink-1" align="right">
                    <button
                      v-if="pageCardIndex == 0"
                      class="iconbutton"
                      style="background-image: url('/img/icon button bg green.svg')"
                    >
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                    <button
                      v-if="pageCardIndex == 1"
                      class="iconbutton"
                      style="background-image: url('/img/icon button bg yellow.svg')"
                    >
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                    <button
                      v-if="pageCardIndex == 2"
                      class="iconbutton"
                      style="background-image: url('/img/icon button bg blue.svg')"
                    >
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                    <button
                      v-if="pageCardIndex == 3"
                      class="iconbutton"
                      style="background-image: url('/img/icon button bg green.svg')"
                    >
                      <span class="material-icons-round iconbuttontext">east</span>
                    </button>
                  </v-col>
                </v-row>
                <p class="pageCardDescription">{{ pageCard.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <!-- Info Text -->
      <v-col cols="12" align="center">
        <v-col cols="12" align="center" xl="3" lg="5" md="6" sm="8" xs="12">
          <!-- <p class="caTitle">
            The main goal of the project is to develop a free online career
            guidance tool
          </p> -->
          <p class="caBody">The main goal of the project is to develop a free online career guidance tool</p>
        </v-col>
      </v-col>

      <!-- News -->
      <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col> -->
      <v-col align="center">
        <v-col cols="12" xl="8" lg="10" md="12" sm="12" xs="12">
          <v-row>
            <!-- Chapter Title -->
            <v-col align="left" cols="12" class="mt-15 pb-0">
              <p class="caTitle">News</p>
            </v-col>

            <v-col align="left" cols="12" v-if="allTheNews == ''">
              <p class="text-center title">No news made public yet.</p>
            </v-col>

            <!-- News Cards -->
            <v-col
              align="left"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              v-for="(newsData, newsDataIndex) in allTheNews.slice(0, 3)"
              :key="newsDataIndex"
            >
              <v-card class="newsCard" height="100%" @click="getNewsContent(newsData)">
                <v-row>
                  <v-col cols="12" class="pb-4">
                    <v-row>
                      <v-col cols="12">
                        <v-img
                          v-if="newsData.media_url"
                          :src="newsData.media_url"
                          alt="News Image"
                          height="200px"
                          cover
                          class="newsImage"
                        ></v-img>
                        <div
                          v-else
                          style="height: 200px; width: auto; background-color: #d1d1d1; margin: 0px 15px 0px 15px"
                        ></div>
                      </v-col>
                      <v-col cols="12" style="" class="pa-3">
                        <p class="pl-2 mb-0 mb-2 newsCardTitle">
                          {{ newsData.title }}
                        </p>
                        <p class="pa-2 pb-0 mb-2 newsCardDescription">
                          {{ newsData.description }}
                        </p>
                      </v-col>
                      <v-col cols="12" class="pt-0 mt-0 ml-2">
                        <button class="cardbutton mb-2">
                          <p class="cardbuttontext">Read more</p>
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- See all news button - go to page -->
      <v-col cols="12" align="center">
        <!-- <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news" v-if="allTheNews != ''"> See all news </v-btn> -->
        <button class="mainbutton" @click="goToNews" v-if="allTheNews != ''">
          <p class="mainbuttontext">See all news</p>
        </button>
      </v-col>

      <!-- RSS -->
      <v-col align="center">
        <v-col cols="12" xl="8" lg="10" md="12" sm="12" xs="12">
          <v-row>
            <v-col align="left" cols="12" class="pt-15 mt-15 pb-0">
              <p class="caTitle">RSS feed</p>
            </v-col>
            <!-- <pre>{{rssEntryContent}}</pre> -->
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="12"
              sm="12"
              xs="12"
              v-for="(rssFeed, rssFeedIndex) in filteredRssEntries"
              :key="rssFeedIndex"
            >
              <a :href="rssFeed.content" target="_blank">
                <!-- <pre>{{rssFeed}}</pre> -->
                <v-card class="rssCard" style="padding: 6px" height="130px" v-if="rssFeed.type == 'rss'">
                  <div class="d-flex">
                    <!-- Image -->
                    <!-- <div
                      class="pa-0"
                      style="
                        background-color: #d1d1d1;
                        border-radius: 7px;
                        height: 114px;
                        width: 114px;
                      "
                    >
                      <v-img
                        v-if="rssFeed.image"
                        :src="rssFeed.image"
                        alt="News Image"
                        style="width: 114px; height: 114px"
                        contain
                      ></v-img>
                      <div v-else style="width: 114px"></div>
                    </div> -->
                    <!-- Title and Content -->
                    <v-row class="pa-2 pl-4">
                      <v-col :cols="$vuetify.breakpoint.xs ? '12' : '9'" class="">
                        <p class="rssCardTitle mb-0">{{ rssFeed.title }}</p>
                        <p class="rssCardDescription mb-0">
                          {{ rssFeed.content }}
                        </p>
                      </v-col>
                      <!-- Date and Arrow -->
                      <v-col align="right" cols="3" :style="$vuetify.breakpoint.xs ? 'display: none' : ''">
                        <p class="rssCardDate text-right" style="margin-bottom: 6px">
                          {{ rssFeed.date }}
                        </p>
                        <button
                          class="iconbutton"
                          style="margin-right: -2px; background-image: url('/img/icon button bg green.svg')"
                        >
                          <span class="material-icons-round iconbuttontext">east</span>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- See all RSS button - go to page -->
      <v-col cols="12" align="center" class="mb-15">
        <!-- <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news" v-if="allTheNews != ''"> See all news </v-btn> -->
        <!-- <button class="mainbutton" to="/rss">
          <p class="mainbuttontext">View RSS feed</p>
        </button> -->
      </v-col>
      <!-- <v-col cols="12" class="mb-15 pb-15" align="center">
        <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/rss"> View RSS feed </v-btn>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/newsDialog.vue";
import RssDialog from "@/components/dialogs/rssFeedDialog.vue";
import RssTest from "@/components/rss/rssTest.vue";
export default {
  name: "Home",
  components: {
    NewsDialog,
    RssDialog,
    RssTest,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      tenant: 120,
      allTheNews: [],
      theNewsContent: [],
      colorArr: ["#205072", "#329D9C", "#D83636", "#DD9A30"],
      contactToast: false,
      rssEntryContent: [],
      // Contact Form
      contactForm: {
        name: "",
        email: "",
        message: "",
        subject: "",
        sentFrom: "path",
      },
      // Rules for the contact Form
      rules: {
        requiredField: (value) => !!value || "This field is required",
        requiredName: (value) => !!value || "Your name is required",
        requiredEmail: (value) => !!value || "A propper email adress is required",
        emailRequirement: (value) => /.+@.+\..+/.test(value) || "A proper email address is required",
      },
      contactToast: false,
      isContactFormValid: true,
      // In Page Pages Cards
      pageCards: [
        {
          name: "About the project",
          color: "",
          link: "/about",
          description: "Read about the project, aims and the results it will produce",
        },
        {
          name: "Path tool",
          color: "",
          link: "/pathtool",
          description: "Learn more about the tool and how to use it",
        },
        {
          name: "Available resources",
          color: "",
          link: "/resources",
          description: "Here you will find different resources made in the project",
        },
        {
          name: "Partners",
          color: "",
          link: "/partners",
          description: "Find the partners and learn more about them",
        },
      ],
      // Dummy data below - Replace when db are implemented
      fakeNewsCards: [
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
        {
          title: "News title",
          article: "Lorem ipsum dolor sit amet, nihil repellat arcitecto asperiores sequi facere",
          external_link: "",
          image: "",
        },
      ],
      fakeRSSfeed: [
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content: "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
      ],
    };
  },
  mounted() {
    this.getAllNews();
    this.getAllRSSEntries();
  },
  methods: {
    // Form: Clear all data
    clearForm() {
      this.$refs.form.reset();
    },
    // Form: Send Data
    postContactForm() {
      const ContactForm = {
        name: this.contactForm.name,
        email: this.contactForm.email,
        subject: this.contactForm.subject,
        message: this.contactForm.message,
        sentFrom: this.contactForm.sentFrom,
      };
      console.log("Sending Contact Form", ContactForm);
      this.messageSent();
      this.clearForm();
    },
    // Form: Notification that message have been sent, through Toast.
    messageSent() {
      this.contactToast = true;
    },

    // Get all news made for this tenant
    getAllNews() {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/news?mode=getpublicnews&tenant_id=${this.tenant}`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          this.allTheNews = response.data.reverse();
          console.log("Responsen", response.data);
        });
    },

    // Get all the content of the clicked news
    getNewsContent(data) {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/news_content?mode=getpublicnews&news_id=${data.id}`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.theNewsContent = response.data;
          console.log("Responsen", response.data);
        })
        .then((responsen) => {
          this.openTheDialog(data, this.theNewsContent);
        });
    },

    // Open the Dialog while passing the correct data
    openTheDialog(originalData, contentData) {
      this.$refs.openingNewsDialog.openNewsDialog(originalData, contentData);
    },

    // RSS
    // #1. Get ALL entries of type RSS and pass them to the iterator
    getAllRSSEntries() {
      this.$http
        .get(`https://app.followup.prios.no/api/resource_management/content?tenant_id=120`, {
          headers: { Tempaccess: this.accessKey },
        })
        .then((response) => {
          console.log("Responsen er", response.data);
          this.rssEntryContent = response.data;
          // let allRssEntriesFromFollowup = response.data;
          // this.getRssSources(allRssEntriesFromFollowup);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    // getRssSources(rssEntries) {
    //   for (let index = 0; index < rssEntries.length; index++) {
    //     if (rssEntries[index].type === "rss") {
    //       this.rssEntryContentList.push(rssEntries[index]); // Push matching RSS entry to the array
    //       console.log("Matching RSS Entry:", rssEntries[index]);
    //     }
    //   }
    // },

    /* #3. Get the actual data, to implement into the html
      - Run a query utilising the parameter from the parent function.
        - Get all RSS Content of each RSS feed.
        - If successful, push to the Main RSS Array, as a new entry.
        - Else, display custom error message.
    */
    getRssContent(rssSource) {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/content?mode=getrssdata&url=${rssSource}&rss_count=3`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.rssEntryContent.push(response.data);
        })
        .catch(function (error) {
          console.log(
            "%cError",
            "color: red; display: block; width: 100%; font-size:36px; font-weight: bold; border:solid black 2px; padding:5px; background-color: lightblue;",
            error.toJSON()
          );
        });
    },
    // Go to news
    goToNews(){
      this.$router.push({name:"News"})
    }
  },
  computed: {
    filteredRssEntries() {
      return this.rssEntryContent.filter((entry) => entry.type === "rss");
    },
    // filteredRssEntries() {
    //   // Define a function to prepend "https://" if needed
    //   function ensureHttps(url) {
    //     if (url.startsWith("http://") || url.startsWith("https://")) {
    //       return url; // URL already has "http://" or "https://"
    //     } else {
    //       return "https://" + url; // Prepend "https://"
    //     }
    //   }

    //   // Filter and modify the RSS entries
    //   return this.rssEntryContent.map(entry => {
    //     if (entry.type === "rss") {
    //       // Check and modify the content URL
    //       if (entry.content && entry.content.url) {
    //         entry.content.url = ensureHttps(entry.content.url);
    //       }
    //     }
    //     return entry;
    //   });
    // }
  },
};
</script>

<style scoped>
/* General Page */
/* .homePageWidth {
  width: 90vw;
  margin: auto auto;
} */

/* Intro Classes for box and text at the top */
/* .introImage {
  height:650px;
  background-color: rgb(213, 211, 211);
} */
.introTitlePhone {
  font-size: 36px;
  color: white;
  opacity: 1;
  margin-left: 5%;
  margin-top: 50%;
  text-shadow: 1px 1px 2px #000000;
}
.introDescription {
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin-left: 17%;
  margin-right: 15%;
  text-shadow: 1px 1px 2px #000000;
}

.introDescriptionPhone {
  font-size: 22px;
  color: white;
  font-family: var(--body-font);
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  text-shadow: 1px 1px 4px #000000;
}
</style>
